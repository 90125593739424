<template>
    <div id="userBox" class="hide-scrollbar" :class="$mq">
        <template v-if="['portrait'].includes($mq)">
            <div class="user-container">
                <div class="img-container">
                    <img class="img" :src="user.avatar" alt="avatar" title="avatar" />
                </div>
                <div class="user-information-container">
                    <div class="name">{{ user.name }} {{ user.surname }}</div>
                    <div class="role">{{ role.name }}</div>
                    <div class="identification" v-if="user.identification">{{ $t('user.id_user_portrait') }}{{ ': ' }}{{ user.identification }}</div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="name">{{ user.name }} {{ user.surname }}</div>
            <div class="role">{{ role.name }}</div>
            <div class="identification" v-if="user.identification">{{ $t('user.id_user') }}{{ ' ' }}{{ user.identification }}</div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        user: Object
    },
    name: 'userBox',
    data() {
        return {
            rol: false
        }
    },
    computed: {
        role() {
            if (this.user.rol) {
                return this.$store.getters['employee/getRol'](this.user.rol)
            }
        }
    },
    created() {},
    mounted() {},
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#userBox {
    @include background($color: #fff);
    padding: 20px;
    border-radius: 6px;

    .name {
        @include text-ellipsis();
        @include font-size(ml);
        font-family: $text-bold;
        color: $neutro-s70;
        padding-bottom: 5px;
    }
    .role {
        @include font-size(m);
        font-family: $text-medium;
        color: $neutro-s50;
        padding-bottom: 15px;
    }
    .identification {
        @include font-size(m);
        font-family: $text-medium;
        color: $neutro-s70;
    }
}
</style>

// PORTRAIT
<style lang="scss">
#userBox.hide-scrollbar.portrait {
    padding: 10px;
    .user-container {
        @include display-flex();

        .img-container {
            width: 25%;
            .img {
                width: 100%;
                height: 100%;
            }
        }
        .user-information-container {
            width: 75%;
            padding-left: 10px;
            align-self: center;

            .name {
                @include font-size(ml);
                font-family: $text-bold;
                overflow: unset;
                width: auto;
                white-space: inherit;
                height: auto;
            }
            .role {
                @include font-size(s);
                font-family: $text-medium;
                padding-bottom: 0px;
                color: $neutro-s70;
            }
            .identification {
                @include font-size(s);
                font-family: $text-medium;
            }
        }
    }
}
</style>
