import { render, staticRenderFns } from "./User.vue?vue&type=template&id=2ad7460c&scoped=true&"
import script from "./User.vue?vue&type=script&lang=js&"
export * from "./User.vue?vue&type=script&lang=js&"
import style0 from "./User.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./User.vue?vue&type=style&index=1&id=2ad7460c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ad7460c",
  null
  
)

export default component.exports